import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shailesh/Dev/Projects/DopeAi/Repos/dopeai-web/src/templates/ghost/page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "indiepreneur",
      "style": {
        "position": "relative"
      }
    }}>{`Indiepreneur`}<a parentName="h2" {...{
        "href": "#indiepreneur",
        "aria-label": "indiepreneur permalink",
        "className": "ml-2 opacity-0 group-hover:opacity-90 inline-block after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "256px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b9b64e92204f370a14a82fa6cc31af0f/e4a55/shailesh.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.16666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAVABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQDAQX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAP/2gAMAwEAAhADEAAAAXNoAq1xHzZie41H/8QAGxAAAgMBAQEAAAAAAAAAAAAAAgMAAREiEhP/2gAIAQEAAQUC9QWFPtkICtact2LqO4l32J8//8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAQ/9oACAEDAQE/ASN//8QAGBEAAgMAAAAAAAAAAAAAAAAAAAECEBL/2gAIAQIBAT8BiaYq/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERIQIQMaH/2gAIAQEABj8C4y+FNjlCLz9ITcaUn//EABwQAQADAQEAAwAAAAAAAAAAAAEAESExUUFhkf/aAAgBAQABPyFqXp8WSgn4Q4APJwkq+xiOfdxyoMBGw1FiWqjK5Hu017P/2gAMAwEAAgADAAAAEJgwPf/EABcRAQEBAQAAAAAAAAAAAAAAAAEAEBH/2gAIAQMBAT8QQ3ON/8QAGBEBAQADAAAAAAAAAAAAAAAAAQAQIUH/2gAIAQIBAT8QSGLhg1f/xAAcEAEBAAIDAQEAAAAAAAAAAAABEQAhMUFRYXH/2gAIAQEAAT8QaY+IcK/fMDF9j7/MQeBLrNzPDnc3DJZl7UAH3vGReLEGEFzKQdyYKUGkF4xICnIjP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b9b64e92204f370a14a82fa6cc31af0f/3e828/shailesh.webp 192w", "/static/b9b64e92204f370a14a82fa6cc31af0f/f4e34/shailesh.webp 256w"],
              "sizes": "(max-width: 256px) 100vw, 256px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b9b64e92204f370a14a82fa6cc31af0f/7809d/shailesh.jpg 192w", "/static/b9b64e92204f370a14a82fa6cc31af0f/e4a55/shailesh.jpg 256w"],
              "sizes": "(max-width: 256px) 100vw, 256px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b9b64e92204f370a14a82fa6cc31af0f/e4a55/shailesh.jpg",
              "alt": "Shailesh, Founder of DopeAI",
              "title": "Shailesh, Founder of DopeAI",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Hi, I am Shailesh, the founder of DopeAI. I love to play with pixels through my code.`}</p>
    <h2 {...{
      "id": "mission",
      "style": {
        "position": "relative"
      }
    }}>{`Mission`}<a parentName="h2" {...{
        "href": "#mission",
        "aria-label": "mission permalink",
        "className": "ml-2 opacity-0 group-hover:opacity-90 inline-block after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Professional photographers go extra mile to capture the cherished memories of our lives. I am on mission to reduce their mundane tasks so they can get more time on creative part and things they love to do.`}</p>
    <p>{`Smartphones are now capable of taking DSLR like photos. Google and Apple are pushing limits of these small cameras with the help of AI. At the same time professional cameras and photography workflows did not advance much in comparison, despite having much advance hardware and optics.`}</p>
    <p>{`While some products do use AI to enhance photos, it is limited to processing and editing of photos. Photo culling tools are still limited to going through all photos with manual effort to check quality and compare similar photos.`}</p>
    <p>{`I want to bridge this gap by making AI available at each stage in whole photography workflow. I am starting with culling step and have launched first product CullAi, to assist in culling photos faster and efficiently.`}</p>
    <h2 {...{
      "id": "core-values",
      "style": {
        "position": "relative"
      }
    }}>{`Core Values`}<a parentName="h2" {...{
        "href": "#core-values",
        "aria-label": "core values permalink",
        "className": "ml-2 opacity-0 group-hover:opacity-90 inline-block after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Speed`}</strong>{` - You only get 8.3ms to render a frame these days, Follow 100ms rule for user interactions.`}</li>
      <li parentName="ul"><strong parentName="li">{`Efficiency`}</strong>{` - Nobody likes their machines overheating as frying pan, making noise like jet plane, causing throttling, and draining 18 hours of designed battery life in just 4 hours`}</li>
      <li parentName="ul"><strong parentName="li">{`Quality`}</strong>{` - Do not sacrifce on quality to achieve speed and efficiency`}</li>
      <li parentName="ul"><strong parentName="li">{`Customer Feedback`}</strong>{` - Is the most important input for product decisions`}</li>
      <li parentName="ul"><strong parentName="li">{`Privacy`}</strong>{` - Respect customer's privacy, they choose who has access to their private data`}</li>
      <li parentName="ul"><strong parentName="li">{`Focus`}</strong>{` - And put in time to do few things really well`}</li>
      <li parentName="ul"><strong parentName="li">{`With your Heart`}</strong>{` - "Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work. And the only way to do great work is to love what you do. If you haven't found it yet, keep looking. Don't settle. As with all matters of the heart, you'll know when you find it." - Steve Jobs`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      